import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FastDeliveryService {
  public baseUrl = environment.apiBaseUrl1 + '/fastDelivery';
  constructor(private http: HttpClient) { }

  createFastDelivery=(data:any)=>{
    return this.http.post(this.baseUrl,data)
  }

  getFastDelivery=(data:any)=>{
    return this.http.get(`${this.baseUrl}?farmId=${data.farmId}`)
  }

  updateStatus=(data:any)=>{
    return this.http.patch(this.baseUrl, data)
  }

  updateFastDelivery=(data:any)=>{
    return this.http.put(this.baseUrl,data)
  }

  updateTimeSlot = (data:any)=>{
    return this.http.put(this.baseUrl+'/timeSlot',data)
  }
}