<h1 class="page-title">Purchase Details</h1>

<!-- Purchase Information Section -->
<div class="purchase-info">
  <div class="info-block">
    <p><strong>Purchase Code:</strong> {{ data.PurchaseCode }}</p>
    <p><strong>Purchase Date:</strong> {{ data.purchaseDate }}</p>
    <p><strong>Type:</strong> {{ data.type }}</p>
    <p><strong>Farm Circle Name:</strong> {{ data.farmCircleName }}</p>
    <p><strong>Farm Name:</strong> {{ data.farmName }}</p>
    <p><strong>Status:</strong> {{ data.status }}</p>
  </div>

  <!-- Total and GST Section -->
  <div class="totals">
    <p><strong>Grand Total:</strong> {{ data.grandTotal | currency: 'INR' }}</p>
    <p><strong>GST Amount:</strong> {{ data.gstAmount | currency: 'INR' }}</p>
  </div>
</div>

<!-- Purchased Products Section -->
<div class="purchase-products">
  <h3>Purchased Products</h3>
  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th *ngFor="let column of displayedColumns">{{ column }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let product of purchaseDetails; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ product.productName }}</td>
          <td>{{ product.categoryName }}</td>
          <td>{{ product.unit }}</td>
          <td>{{ product.unitValue }}</td>
          <td>{{ product.price | currency: 'INR' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
