import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-detail-popup',
  templateUrl: './purchase-detail-popup.component.html',
  styleUrls: ['./purchase-detail-popup.component.scss'],
})
export class PurchaseDetailPopupComponent implements OnInit {
  // Define table column headers
  displayedColumns: string[] = [
    'Product #',
    'Product Name',
    'Category Name',
    'Unit',
    'Unit Value',
    'Price',
  ];

  // Hold the table data
  purchaseDetails: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    // Log the data to ensure it's coming in correctly
    console.log(this.data);

    // Assign 'data.purchaseDetails' to 'purchaseDetails' for table rendering
    this.purchaseDetails = this.data.purchaseDetails || [];
  }
}
