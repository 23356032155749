<div class="container">
  <!-- Two-Column Layout -->
  <div class="row-layout">
    <!-- Fast Delivery Form Section -->
    <mat-card class="form-card">
      <mat-card-header>
        <h4 class="form-title">Fast Delivery</h4>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="fastDeliveryEntryForm" (ngSubmit)="onSubmit()" class="form-section">
          <div fxLayout="row wrap" fxLayoutGap="24px">
            <!-- Duration Field -->
            <mat-form-field fxFlex="48%" appearance="fill" class="form-field">
              <mat-label>Duration</mat-label>
              <input formControlName="duration" matInput required />
              <mat-error *ngIf="fastDeliveryEntryForm.get('duration')?.invalid && fastDeliveryEntryForm.get('duration')?.touched">
                Duration is required
              </mat-error>
            </mat-form-field>
      
            <!-- Duration Type Field -->
            <mat-form-field fxFlex="48%" appearance="fill" class="form-field">
              <mat-label>Duration Type</mat-label>
              <input formControlName="durationType" matInput required />
              <mat-error *ngIf="fastDeliveryEntryForm.get('durationType')?.invalid && fastDeliveryEntryForm.get('durationType')?.touched">
                Duration Type is required
              </mat-error>
            </mat-form-field>
      
            <!-- Amount Field -->
            <mat-form-field fxFlex="48%" appearance="fill" class="form-field">
              <mat-label>Amount</mat-label>
              <input formControlName="amount" matInput required />
              <mat-error *ngIf="fastDeliveryEntryForm.get('amount')?.invalid && fastDeliveryEntryForm.get('amount')?.touched">
                Amount is required
              </mat-error>
            </mat-form-field>
      
            <!-- Delivery Radius Field -->
            <mat-form-field fxFlex="48%" appearance="fill" class="form-field">
              <mat-label>Delivery Radius</mat-label>
              <input formControlName="deliveryRadius" matInput required />
              <mat-error *ngIf="fastDeliveryEntryForm.get('deliveryRadius')?.invalid && fastDeliveryEntryForm.get('deliveryRadius')?.touched">
                Delivery Radius is required
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Submit Button -->
          <div fxLayout="row" fxLayoutAlign="end center" class="button-row">
            <button mat-raised-button color="primary" [disabled]="fastDeliveryEntryForm.invalid">
              Submit
            </button>
          </div>
        </form>
      </mat-card-content>
      
    </mat-card>

    <!-- Timing Slot Section -->
    <mat-card class="card">
      <mat-card-header class="card-header">
        <h4 class="title">Timing Slot</h4>
        <button mat-icon-button color="accent" (click)="openDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content>
        <ul class="timing-list">
          <li *ngFor="let timing of fastDeliveryEntryForm.get('timeSlot').value" class="timing-item">
            <div class="timing-details">
              <input
                type="checkbox"
                [checked]="timing.status"
                (change)="onTimingStatusChange(timing, $event)"
              />
              <span>{{ timing.timing }}</span>
            </div>
            
            <div class="actions">
              <!-- Edit Icon -->
              <button mat-icon-button color="primary" (click)="openDialog(timing)">
                <mat-icon>edit</mat-icon>
              </button>
              <!-- Delete Icon -->
              <button mat-icon-button color="warn" (click)="onDelete(timing)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>
