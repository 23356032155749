import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, filter, startWith } from 'rxjs/operators';
import { SidebarComponent } from 'src/@vex/components/sidebar/sidebar.component';
import { ConfigService } from 'src/@vex/services/config.service';
import { LayoutService } from 'src/@vex/services/layout.service';
import { checkRouterChildsData } from 'src/@vex/utils/check-router-childs-data';
import { User } from 'src/app/account-management/model/user';
import { Farm } from 'src/app/farm/model/farm';
import { FarmService } from 'src/app/farm/service/farm.service';
import { RsaService } from 'src/app/shared/service/rsa.service';

@UntilDestroy()
@Component({
  selector: 'app-farm-layout',
  templateUrl: './farm-layout.component.html',
  styleUrls: ['./farm-layout.component.scss']
})
export class FarmLayoutComponent implements OnInit {

  public id;
  public farmList: Farm;
  public loginType;
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  public users: User;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config?.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled!))
  );

  @ViewChild('configpanel', { static: true }) configpanel?: SidebarComponent;

  constructor(private layoutService: LayoutService, private route: ActivatedRoute,
    private configService: ConfigService, private snackbar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private router: Router, private rsaService: RsaService, private farmService: FarmService) {
    this.route.queryParams.subscribe((data: any) => {
      if (data.sid) {
        this.id = data.sid;
        localStorage.setItem('id', this.id);
      } else {
        this.id = localStorage.getItem('id');
      }
    })
  }

  ngOnInit() {
    let user = localStorage.getItem('4')
    this.users = JSON.parse(this.rsaService.decrypt(user));
    this.loginType = localStorage.getItem('7');
    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel?.open() : this.configpanel?.close());
    this.getFarmDetails();
  }

  getFarmDetails() {
    this.farmService.getfarmDetailsById(this.id).subscribe((data: any) => {
      this.farmList = data.result;

      if (!this.farmList || !this.farmList.farmCode) {
        this.snackbar.open("Session Expired ! Please login again.", '', {
          duration: 10000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        setTimeout(() => {
          localStorage.clear();
          this.router.navigateByUrl('/auth/login');
        }, 1000);
      }
    }, (error) => {
      console.log(error);
    })
  }


}
