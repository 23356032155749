<div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async" class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center">
    <img src="../../../../assets/img/demo/Logos.png" alt="" srcset="">
  </div>

  <app-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <app-sidenav-item *ngFor="let item of items; trackBy: trackByRoute" [item]="item" [level]="0"></app-sidenav-item>
    </div>
  </app-scrollbar>
</div>
