import { Component, OnInit } from '@angular/core';
import { FastDelivery } from '../../models/fast-delivery';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FastDeliveryService } from '../../service/fast-delivery.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RsaService } from 'src/app/shared/service/rsa.service';

@Component({
  selector: 'app-fast-delivery',
  templateUrl: './fast-delivery.component.html',
  styleUrls: ['./fast-delivery.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class FastDeliveryComponent implements OnInit {
  public fastDeliveryEntry: FastDelivery | null = null; 
  fastDeliveryEntryForm: FormGroup;

  constructor(private fastDeliveryService: FastDeliveryService,private snackbar: MatSnackBar, private rsaService: RsaService,) {
    // Initialize the reactive form
    this.fastDeliveryEntryForm = new FormGroup({
      status: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
      deliveryRadius: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required]),
      durationType: new FormControl('', [Validators.required]),
      farmId: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getFastDelivery();
  }

  // Fetch the fast delivery data
  getFastDelivery(): void {
    const farmId = localStorage.getItem('farmId');
    if (!farmId) {
      console.error('Farm ID is not available in local storage.');
      return;
    }

    const details = { farmId };
    this.fastDeliveryService.getFastDelivery(details).subscribe(
      (res: any) => {
        if (res && res.result) {
          this.fastDeliveryEntry = res.result;
          this.populateForm(res.result);
        }
      },
      (error) => {
        console.error('Error fetching fast delivery details:', error);
      }
    );
  }


  populateForm(data: FastDelivery): void {
    this.fastDeliveryEntryForm.patchValue({
      status: data.status || '',
      amount: data.amount || '',
      deliveryRadius: data.deliveryRadius || '',
      duration: data.duration || '',
      durationType: data.durationType || '',
      farmId: data.farmId || '',
    });
  }


  onSubmit(): void {
    if (this.fastDeliveryEntryForm.valid) {
      const formData = this.fastDeliveryEntryForm.value;
      console.log('Form Data:', formData);
      this.fastDeliveryService.updateFastDelivery(formData).subscribe(
        (response) => {
          console.log('Data saved successfully:', response);
        },
        (error) => {
          this.snackbar.open(" Do not Change the Previous Status !", '', {
            duration: 5000
          });
        }
      );
    } else {
      console.error('Form is invalid');
    }
  }
}
