<div class="account-details">
    <h2>Accounting Entry Details</h2>
    <div class="entry-section">
      <div class="entry-row">
        <p><strong>Invoice Number:</strong> {{ data.invoiceNo }}</p>
        <p><strong>Account Number:</strong> {{ data.accountNo }}</p>
      </div>
      <div class="entry-row">
        <p><strong>Accounting Type:</strong> {{ data.accountingType }}</p>
        <p><strong>Credit/Debit Code:</strong> {{ data.creditDebitEntryCode }}</p>
      </div>
    </div>
  
    <h3>Farm Details</h3>
    <div class="farm-section">
      <div class="farm-row">
        <p><strong>Farm Name:</strong> {{ data.farmId.farmName }}</p>
        <p><strong>Farm Code:</strong> {{ data.farmId.farmCode }}</p>
      </div>
      <div class="farm-row">
        <p><strong>Address:</strong> {{ data.farmId.address }}</p>
      </div>
      <div class="farm-row">
        <p><strong>Delivery Radius:</strong> {{ data.farmId.deliveryRadius }}</p>
        <p><strong>Farm Circle Name:</strong> {{ data.farmCircleId.farmCircleName }}</p>
      </div>
    </div>
  
    <h3>Transaction Details</h3>
    <div class="transaction-section">
      <div class="transaction-row">
        <p><strong>Total Amount:</strong> {{ data.totalAmount | currency:'INR' }}</p>
        <p><strong>Payment Reference:</strong> {{ data.paymentRef }}</p>
      </div>
      <div class="transaction-row">
        <p><strong>Payment Type:</strong> {{ data.paymentType }}</p>
        <p><strong>Currency:</strong> {{ data.currency }}</p>
      </div>
      <div class="transaction-row">
        <p><strong>Date:</strong> {{ data.date }}</p>

      </div>
    </div>
  
    <h3>Other Information</h3>
    <div class="additional-info">
      <div class="info-row">
        <p><strong>Created By:</strong> {{ data.createdBy }}</p>
        <p><strong>Created On:</strong> {{ data.createdOn | date:'medium' }}</p>
      </div>
      <div class="info-row">
        <p><strong>Modified By:</strong> {{ data.modifiedBy }}</p>
        <p><strong>Modified On:</strong> {{ data.modifiedOn | date:'medium' }}</p>
      </div>
      
    </div>
  </div>
  
  <mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="close()">Close</button>
  </mat-dialog-actions>
  